import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import List from '../components/list';
import Seo from "../components/seo";

import * as themes from '../themes/schema.json';
import { setToLS } from '../utils/storage';
import { getCsvData } from '../utils/objktsData';


const IndexPage = () => {
  setToLS('all-themes', themes.default);
  const [objkts, setObjkts] = useState([]);

  useEffect(() => {
    getCsvData().then((result) => {
      setObjkts(result);
    });
  }, []);

  return (
    <Layout>
      <Seo title="Isobar NFT" />
      <p>Hi! We are a group of Dentsu NFT artists from various disciplines and styles.</p>
      {objkts.length ? (
        <List objkts={objkts} />
      ) : (
        <p>Loading ...</p>
      )}
    </Layout>
  );
}

export default IndexPage;
